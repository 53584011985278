import React, { useMemo } from 'react'
import { Modal } from '@thepokencompany/ui'
import { useMediaQuery } from 'react-responsive'

import { useModalContext } from '../utils/modal'
import { useUserContext } from '../context/user'

import App from './App'
import Header from './Header'
import { ToastContainer } from './base/Toaster'

interface LayoutProps {
  acl?: string[]
  children?: any
  needAge?: boolean
  publicPage?: boolean
  title?: string
  onBack?: Function
}

function AppLayout(props: LayoutProps) {
  const { children, acl } = props
  const { isActionAllowed } = useUserContext()
  const { content, closeModal, preventClose } = useModalContext()
  const isMediumScreen = useMediaQuery({ minWidth: 768 })

  const containerStyle = useMemo(
    () => ({
      // display: 'flex',
      // flexDirection: 'column' as const,
      minHeight: `calc(100vh - ${isMediumScreen ? 56 : 112}px)`
    }),
    [isMediumScreen]
  )

  return (
    <App>
      <Header />

      {!acl || isActionAllowed(acl) ? (
        <div style={containerStyle}>{children}</div>
      ) : (
        <div className='mt-9 text-center'>
          <p>You are not allowed to access this page</p>
        </div>
      )}

      <ToastContainer />
      <Modal open={!!content} onClose={closeModal} preventClose={preventClose}>
        {content}
      </Modal>

      <div className='text-xs max-w-screen-xl mx-auto w-full py-8'>
        The Site is published and managed by INNOVATIVE CLOUD TECH, a simplified stock company with a capital of 215
        055€, registered in the Paris Trade and Companies Register under the number B 899 756 340 and whose registered
        office is located at 14 Rue du Printemps Paris. The director of the publication is Nils LATAILLADE, President of
        the company.{' '}
        <a
          href='https://legal.pokmi.com/18-u-s-c-%c2%a72257-record-keeping-requirements-compliance-statement/'
          target={'_blank'}
          rel='noreferrer'
        >
          18 U.S.C. §2257 Record Keeping Requirements Compliance Statement
        </a>
      </div>
    </App>
  )
}

export default React.memo(AppLayout)
