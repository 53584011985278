import { ToastContainer as Container, toast as toaster } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const toast = toaster

export function ToastContainer() {
  return (
    <Container
      position='top-right'
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  )
}
