import React from 'react'
import { Skeleton } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  user: {
    display: 'flex',
    width: '100%',
    margin: '16px 0',
    padding: '0 8px'
  },
  textUser: {
    width: '80% !important',
    marginLeft: '8px'
  }
}))

const Item = () => {
  const classes = useStyles()

  return (
    <div className={classes.user}>
      <Skeleton variant='circular' width={32} height={32} animation='wave' />
      <Skeleton className={classes.textUser} variant='text' width={210} animation='wave' />
    </div>
  )
}

function SearchListSkeleton() {
  return (
    <>
      <Item />
      <Item />
      <Item />
      <Item />
    </>
  )
}

export default SearchListSkeleton
