import React from 'react'
import Link from 'next/link'

import Avatar from '../base/Avatar'
import { truncateWallet } from '../../utils'

import SearchListSkeleton from './SearchListSkeleton'

interface SearchListProps {
  isSearching: boolean
  path: string
  value?: string
  label?: string
  items?: any[]
}

function SearchList({ isSearching, label, path, items }: SearchListProps) {
  return items?.length ? (
    <div className=''>
      {label && <h3 className='text-white p-2'>{label}</h3>}
      {!isSearching &&
        items.map((item) => (
          <Link key={item?.id} href={`/${path}/${item.id}`}>
            <div className='flex items-center w-full cursor-pointer hover:bg-neutral-2 p-2'>
              <Avatar
                className='w-8 h-8'
                image={
                  item?.file_thumbnail ||
                  item?.file_compressed ||
                  item?.animation_url ||
                  item?.image ||
                  item?.picture ||
                  item?.profile_picture
                }
                name={item?.name}
              />
              <div className='flex items-center mx-2 text-neutral-4 w-4/5'>
                <p className='truncate text-ellipsis'>
                  {item.name ||
                    item?.username ||
                    truncateWallet(item?.wallet || item?.cloud_wallet) ||
                    truncateWallet(item?.id)}
                </p>
              </div>
            </div>
          </Link>
        ))}
      {isSearching && <SearchListSkeleton />}
    </div>
  ) : null
}

export default SearchList
