import { useMemo } from 'react'

export default function ImageWrapper(props) {
  const { src, type, muted, threshold, poster, parentRef, blurred, noPlayIcon, playInViewPort, priority, ...rest } =
    props

  const onImgError = (e) => {
    e.target.src = '/images/Image_Placeholder.png'
  }

  const image = useMemo(() => {
    const imgSrc = src || ''
    return (
      <img
        {...rest}
        src={imgSrc}
        className={`w-full ${props.className}`}
        onError={onImgError}
        loading={priority ? 'eager' : 'lazy'}
      />
    )
  }, [src, props.className])

  return image
}
