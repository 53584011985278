import React, { useState } from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { SwipeableDrawer } from '@mui/material'
import { MenuIcon } from '@heroicons/react/solid'

import { IS_PROD } from '../../utils/constants'
import SearchInput from '../Search/SearchInput'

import NavBar from './NavBar'
import NavSideBar from './NavSideBar'

function Header({ className = '' }) {
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false)

  const logo = (
    <Link href={'/moderation'}>
      <a className='flex flex-shrink-0'>
        <img
          src={'/images/pokmi_logo.svg'}
          className={clsx('z-50 cursor-pointer h-6', !IS_PROD && 'hue-rotate-180 filter')}
        />
        <span className='ml-4 font-bold text-xl text-white'>Admin</span>
      </a>
    </Link>
  )

  return (
    <header className={clsx(`!z-50 flex items-center h-14 w-full sticky top-0 px-2 backdrop-blur`, className)}>
      <div className='flex w-full justify-between items-center'>
        {logo}
        <SearchInput />
      </div>
      <NavBar className='hidden sm:flex items-center' />
      <MenuIcon className='h-8 w-8 cursor-pointer' style={{ zIndex: 2000 }} onClick={() => setBurgerMenuOpen(true)} />
      <SwipeableDrawer
        open={burgerMenuOpen}
        anchor='right'
        onClose={() => setBurgerMenuOpen(false)}
        onOpen={() => setBurgerMenuOpen(true)}
      >
        <div className='bg-neutral-1 h-full p-4 pl-4 pr-32'>
          {logo}
          <NavSideBar className='flex flex-col mx-auto overflow-hidden mt-4 items-start w-full' />
        </div>
      </SwipeableDrawer>
    </header>
  )
}

export default React.memo(Header)
