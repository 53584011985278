import { useMemo } from 'react'

import Asset from '../base/Asset'

export default function Avatar(props) {
  const { image, name, className, onClick } = props

  const h = className?.match(/(?:^|\s)h-\w+/g)?.[0] || 'h-8'
  const w = className?.match(/(?:^|\s)w-\w+/g)?.[0] || 'w-8'

  const userIcon = useMemo(() => {
    return image ? (
      <Asset
        className={`w-full h-full object-cover ${h} ${w}`}
        src={image}
        alt='user-avatar'
        onClick={onClick || (() => {})}
        width={150}
        height={150}
        forceGifResize={props.forceGifResize}
        resize={{ width: 150, height: 150 }}
        noPlayIcon={true}
      />
    ) : (
      <img src='/images/Poken.svg' onClick={onClick || (() => {})} />
    )
  }, [image, name])

  return (
    <div className={`overflow-hidden rounded-full ${className} ${h} ${w} ${onClick ? 'cursor-pointer' : ''}`}>
      {userIcon}
    </div>
  )
}
