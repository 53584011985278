import React from 'react'
import { SearchIcon } from '@heroicons/react/outline'
import { XCircleIcon, XIcon } from '@heroicons/react/solid'
import { useRouter } from 'next/router'

import { useSearchContext } from '../../utils/search'

import SearchDropdown from './SearchDropdown'

function SearchInput() {
  const { searchValue, setSearchValue, searchHistory, setSearchHistory } = useSearchContext()
  const router = useRouter()

  const [isOpen, setIsOpen] = React.useState(false)
  const [showMobileInput, setShowMobileInput] = React.useState(false)
  const inputRef = React.useRef(null)

  const onSearch = () => {
    setIsOpen(false)

    if (searchValue && !searchHistory.includes(searchValue)) {
      const newHistory = [...searchHistory]
      if (newHistory.length >= 5) newHistory.pop()
      setSearchHistory([searchValue, ...newHistory])
    }
    router.push({ pathname: '/search', query: searchValue ? { s: searchValue } : {} })
  }

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSearch()
    }
  }

  React.useEffect(() => {
    if (showMobileInput) inputRef.current.focus()
  }, [showMobileInput])

  React.useEffect(() => {
    document.body.style['overflow-y'] = isOpen ? 'hidden' : 'scroll'
    document.body.style['touch-action'] = isOpen ? 'none' : 'auto'
  }, [isOpen])

  return (
    <>
      {isOpen && <div className='absolute inset-0 w-screen h-screen' onClick={() => setIsOpen(false)} />}
      <div className='md:relative mx-4 max-w-sm md:w-full'>
        <div className={`flex md:hidden cursor-pointer`} onClick={() => setShowMobileInput(true)}>
          <SearchIcon className='w-5 text-gtxt2' />
        </div>
        <div
          className={`bg-black md:bg-transparent w-full ${
            showMobileInput ? 'absolute inset-0 px-2 flex items-center md:hidden' : 'relative hidden md:flex'
          }`}
          style={{ zIndex: 2001 }}
        >
          <div
            className={`flex border border-blight bg-neutral-1 pl-2 h-9 w-full rounded-lg ${
              isOpen ? 'md:rounded-t-lg md:rounded-b-none md:border-b-0' : 'rounded-lg'
            } `}
          >
            <SearchIcon className='w-5 cursor-pointer text-gtxt2' onClick={onSearch} />
            <input
              className='h-full w-full outline-none bg-neutral-1 text-white mx-2 border-none'
              type='text'
              name='Search'
              placeholder={'Search'}
              autoComplete='off'
              onChange={(event) => {
                const value = event.target.value
                if (value !== ' ') setSearchValue(value)
              }}
              value={searchValue}
              onFocus={() => setIsOpen(true)}
              onKeyPress={onKeyPress}
              ref={inputRef}
            />
            {searchValue?.length ? (
              <XCircleIcon className='w-5 mr-2 cursor-pointer' onClick={() => setSearchValue('')} />
            ) : null}
          </div>
          <XIcon
            className={`w-5 ml-2 cursor-pointer md:hidden z-50`}
            onClick={() => {
              setShowMobileInput(false)
              setIsOpen(false)
            }}
          />
        </div>
        {isOpen && (
          <SearchDropdown
            onSearch={() => {
              setShowMobileInput(false)
              setIsOpen(false)
            }}
          />
        )}
      </div>
    </>
  )
}

export default SearchInput
