import React from 'react'
import Link from 'next/link'
import { XIcon } from '@heroicons/react/outline'
import { useMediaQuery } from 'react-responsive'

import Avatar from '../base/Avatar'
import { useSearchContext } from '../../utils/search'
import { addressLink } from '../../utils/profile'

import SearchList from './SearchList'

const sections = [
  {
    path: 'nfps',
    value: 'nfts',
    label: 'NFT'
  },
  {
    path: 'profile',
    value: 'creators',
    label: 'Creators'
  },
  {
    path: 'pokandplay',
    value: 'pokAndPlays',
    label: 'Pok&Play'
  },
  {
    path: 'profile',
    value: 'users',
    label: 'Profiles'
  }
]

function SearchDropdown({ onSearch }) {
  const isXLargeScreen = useMediaQuery({ minWidth: 1280 })
  const isLargeScreen = useMediaQuery({ minWidth: 1024 })

  const searchContext = useSearchContext()
  const { isSearching, searchValue, setSearchValue, searchHistory, setSearchHistory } = searchContext

  const history = React.useMemo(
    () =>
      searchHistory?.length ? (
        <div className='px-2'>
          <h3 className='text-white'>Recent Searches</h3>
          <div className='mt-2 mb-4 text-neutral-4'>
            {searchHistory.map((search, index) => (
              <div
                key={index}
                className='flex justify-between cursor-pointer'
                onClick={() => {
                  setSearchValue(search)
                  onSearch()
                }}
              >
                <p className='italic truncate'>{search}</p>
                <XIcon
                  className='w-4 cursor-pointer'
                  onClick={(e) => {
                    e.stopPropagation()
                    const newHistory = [...searchHistory]
                    newHistory.splice(index, 1)
                    setSearchHistory(newHistory)
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      ) : null,
    [searchHistory]
  )

  const hasResult = !!sections.find((section) => searchContext?.[section.value]?.hits?.length)

  return (
    <div className='!z-50 absolute left-0 top-12 md:top-9 w-screen md:w-full h-screen md:h-auto border-0 md:border border-blight bg-black md:bg-neutral-1 rounded-b-lg overflow-hidden'>
      <div
        className='!z-50 w-full py-4 overflow-y-scroll'
        style={{ maxHeight: isLargeScreen || isXLargeScreen ? 'calc(100vh - 150px)' : 'calc(100vh - 56px)' }}
      >
        {!searchValue ? (
          <div className='flex flex-col'>
            {history}
            <div>
              <h3 className='text-white p-2'>Trending Creators</h3>
              {searchContext?.creators?.hits?.slice(0, 5).map((creator) => (
                <Link key={creator.id} href={`/profile/${creator?.id}`}>
                  <div className='flex items-center w-full cursor-pointer hover:bg-neutral-2 p-2' onClick={onSearch}>
                    <Avatar className='w-8 h-8' image={creator?.profile_picture} name={creator?.name} />
                    <span className='flex items-center mx-2 text-neutral-4 truncate'>
                      {addressLink(creator)}
                      <img
                        src='/images/certified.png'
                        className={`${creator?.certified ? 'block' : 'hidden'} h-3 w-3 ml-1`}
                        alt='certified'
                      />
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ) : (
          <div className='flex flex-col overflow-hidden'>
            <div className='w-full'>
              {hasResult ? (
                sections.map((section, index) => (
                  <SearchList
                    key={index}
                    isSearching={isSearching}
                    path={section.path}
                    value={section.value}
                    label={section.label}
                    items={searchContext?.[section.value]?.hits?.slice(0, 5)}
                  />
                ))
              ) : (
                <div className='flex justify-center'>
                  <p>No results</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchDropdown
