import React from 'react'
import clsx from 'clsx'
import Link from 'next/link'

import { useUserContext } from '../../context/user'
import { capitalize } from '../../utils'

function NavBar({ className = '', ...rest }) {
  const { user, logout } = useUserContext()

  const iconClass = 'h-8 mx-2 md:mx-2 mt-2 md:mt-0 cursor-pointer relative flex gap-2 items-center'
  const labelClass = 'flex whitespace-nowrap hover:bg-neutral-2 p-2 rounded-md'
  const sublabelClass = `${labelClass} mx-2`

  const name = user?.email?.split('@')[0]
  const [firstName, lastname] = name.split('.')

  return (
    <nav className={clsx(`flex text-white mr-6`, className)} {...rest}>
      <div>
        <div className='flex gap-2 w-full mb-4'>
          <div className='flex flex-col flex-1 truncate'>
            <p className=' text-neutral-8 break-words w-full '>{`Connected as ${capitalize(
              firstName || ''
            )} ${capitalize(lastname || '')}`}</p>
            <p className='mt-1 text-neutral-5 text-xs break-words w-full truncate'>{user?.email}</p>
            <div className='w-full flex text-red cursor-pointer text-sm mb-2 mt-3' onClick={logout}>
              <i>
                <img src='/images/icons/brokenLink.svg' className='w-4 mr-2' />
              </i>
              <span>Disconnect</span>
            </div>
          </div>
        </div>
      </div>
      <div className='my-4'>
        <Link href={`/moderation`}>
          <a className={iconClass}>
            <span className={`${labelClass} font-bold`}>Moderation</span>
          </a>
        </Link>
        <Link href={`/nfts`}>
          <a className={iconClass}>
            <span className={sublabelClass}>NFTs</span>
          </a>
        </Link>
        <Link href={`/hellos`}>
          <a className={iconClass}>
            <span className={sublabelClass}>Hellos</span>
          </a>
        </Link>
        <Link href={`/reports`}>
          <a className={iconClass}>
            <span className={sublabelClass}>Reports</span>
          </a>
        </Link>
        <Link href={`/kyc`}>
          <a className={iconClass}>
            <span className={sublabelClass}>KYCs</span>
          </a>
        </Link>
      </div>

      <div className='my-4'>
        <span className={`${labelClass} font-bold`}>Promotion</span>
        <Link href={`/vouchers`}>
          <a className={iconClass}>
            <span className={sublabelClass}>Vouchers</span>
          </a>
        </Link>
        <Link href={`/free-ticket`}>
          <a className={iconClass}>
            <span className={sublabelClass}>Free Tickets</span>
          </a>
        </Link>
      </div>

      <div>
        <Link href={`/editorial`}>
          <a className={iconClass}>
            <span className={`${labelClass} font-bold`}>Editorial</span>
          </a>
        </Link>
      </div>

      <div>
        <Link href={`/create`}>
          <a className={iconClass}>
            <span className={`${labelClass} font-bold`}>Creation</span>
          </a>
        </Link>
      </div>

      <div>
        <Link href={`/airdrops`}>
          <a className={iconClass}>
            <span className={`${labelClass} font-bold`}>Airdrops</span>
          </a>
        </Link>
      </div>

      <div>
        <Link href={`/allocations`}>
          <a className={iconClass}>
            <span className={`${labelClass} font-bold`}>Allocations</span>
          </a>
        </Link>
      </div>
    </nav>
  )
}

export default React.memo(NavBar)
