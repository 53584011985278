import clsx from 'clsx'

import { isVideo } from '../../utils'
import VideoPlayer from '../VideoPlayer'

import VideoWrapper from './VideoWrapper'
import ImageWrapper from './ImageWrapper'

export default function Asset(props) {
  const { forceGifResize, ...rest } = props

  if (!props.src) return null

  const resizableURLmarker = /cdn(?:-staging)?\.(?:pokmi|rare-porn)\.com\/.*\.(?:(?!gif).)/g
  const resizableURLmarkerGif = /cdn(?:-staging)?\.(?:pokmi|rare-porn)\.com\/.*/g

  let src = props.src

  if (!isVideo(src) && props.resize && src?.match(forceGifResize ? resizableURLmarkerGif : resizableURLmarker)) {
    src = overrideImageUrl({ url: src, resize: { width: props.resize?.width, height: props.resize?.height } })
  }
  const VideoComp = props?.useVideoPlayer ? VideoPlayer : VideoWrapper

  return (
    <>
      {isVideo(src) ? (
        <VideoComp {...rest} src={src} className={clsx(props.className)} />
      ) : (
        <ImageWrapper {...rest} src={src} className={clsx(props.className)} />
      )}
    </>
  )
}

interface OverrideImageUrlOptions {
  url: string
  resize: { width: number; height: number }
  blur?: number | boolean
  locked?: boolean
}

export function overrideImageUrl(options: OverrideImageUrlOptions) {
  if (typeof options.url !== 'string') return

  const RP_HOST = 'rare-porn.com/'
  const POKMI_HOST = 'pokmi.com/'
  let pathPrefix = `resize/${options.resize.width}x${options.resize.height}/`

  if (options.blur) pathPrefix += typeof options.blur === 'number' ? `blur-${options.blur}/` : 'blur/'
  if (options.locked) pathPrefix += 'lock/'

  return options.url.replace(RP_HOST, `${RP_HOST}${pathPrefix}`).replace(POKMI_HOST, `${POKMI_HOST}${pathPrefix}`)
}
