import React from 'react'
import clsx from 'clsx'
import Link from 'next/link'

function NavBar({ className = '', ...rest }) {
  const iconClass = 'h-8 mx-2 md:mx-2 mt-2 md:mt-0 cursor-pointer relative flex gap-2 items-center'
  const labelClass = 'flex mx-2 whitespace-nowrap	hover:bg-neutral-2 p-2 rounded-md'

  return (
    <nav className={clsx(`flex text-white mr-6 `, className)} {...rest}>
      <Link href={`/moderation`}>
        <a className={iconClass}>
          <span className={labelClass}>Moderation</span>
        </a>
      </Link>
      <Link href={`/create`}>
        <a className={iconClass}>
          <span className={labelClass}>Creation</span>
        </a>
      </Link>
      <Link href={'/editorial'}>
        <a className={iconClass}>
          <span className={labelClass}>Editorial</span>
        </a>
      </Link>
    </nav>
  )
}

export default React.memo(NavBar)
