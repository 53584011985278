import { useCallback, useEffect, useRef, useState } from 'react'
import { VolumeUpIcon, VolumeOffIcon } from '@heroicons/react/solid'
import useIsInViewport from 'use-is-in-viewport'
import * as Sentry from '@sentry/nextjs'

import { useConfigContext } from '../../context/config'

export interface VideoWrapperProps {
  src: string
  parentRef: React.RefObject<HTMLElement>
  muted?: boolean
  mutable?: boolean
  showMutable?: boolean
  noPlayIcon?: boolean
  playInViewPort?: boolean
  threshold?: number
  onLoaded?: Function
  onLoadStart?: Function
  poster?: string
  playOnHover?: boolean
}

export default function VideoWrapper(props: VideoWrapperProps) {
  const {
    src,
    parentRef,
    muted = true,
    mutable = true,
    showMutable = false,
    threshold = 53,
    poster,
    noPlayIcon = false,
    playInViewPort = false,
    playOnHover = false,
    onLoaded = () => {},
    onLoadStart = () => {},
    ...rest
  } = props
  const { isMuted, setIsMuted } = useConfigContext()

  const [isPlaying, setIsPlaying] = useState(false)
  const [isInViewport, targetRef] = useIsInViewport({ threshold, viewport: parentRef })

  const videoRef = useRef(null)

  const setPlay = useCallback(() => {
    setIsPlaying(true)
  }, [])
  const setPause = useCallback(() => {
    setIsPlaying(false)
  }, [])

  useEffect(() => {
    videoRef.current?.addEventListener('play', setPlay)
    videoRef.current?.addEventListener('pause', setPause)

    return () => {
      if (!videoRef.current) return
      videoRef.current?.removeEventListener('play', setPlay)
      videoRef.current?.removeEventListener('pause', setPause)
    }
  }, [videoRef.current])

  useEffect(() => {
    playInViewPort && videoRef.current?.load()
  }, [src])

  useEffect(() => {
    try {
      if (isInViewport && playInViewPort) {
        videoRef.current?.play()
      } else {
        if (videoRef.current?.play) {
          videoRef.current?.pause()
          // videoRef.current.currentTime = 0
        }
      }
    } catch (err) {
      Sentry.captureException(err)
    }
  }, [isInViewport])

  const onUpdateSound = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setIsMuted(!isMuted)
  }

  const playPause = useCallback((e) => {
    e.stopPropagation()
    videoRef.current?.paused ? videoRef.current?.play() : videoRef.current?.pause()
  }, [])

  const play = useCallback((e) => {
    e.stopPropagation()
    videoRef.current?.play()
  }, [])

  const pause = useCallback((e) => {
    e.stopPropagation()
    videoRef.current?.pause()
  }, [])

  return (
    <div className={'h-full w-full'} onClick={playPause} ref={targetRef}>
      <video
        loop
        playsInline
        poster={poster}
        className='rounded-md w-full h-full object-fill'
        muted={mutable ? isMuted : muted}
        {...rest}
        ref={videoRef}
        onMouseEnter={(e) => playOnHover && play(e)}
        onMouseLeave={(e) => playOnHover && pause(e)}
      >
        <source src={src} type='video/mp4' />
      </video>
      {showMutable && (
        <div className={`absolute cursor-pointer top-2 right-2 z-40`} onClick={onUpdateSound}>
          <div className='w-10 h-10 rounded-full bg-neutral-3 bg-opacity-70 flex items-center justify-center'>
            {isMuted ? <VolumeOffIcon className='h-5 w-5' /> : <VolumeUpIcon className='h-5 w-5' />}
          </div>
        </div>
      )}
      {isPlaying || noPlayIcon || (
        <div className={`absolute cursor-pointer top-1/2 left-1/2 -ml-8 -mt-8 z-10`}>
          <img src='/images/play.svg' className='w-16 h-16' />
        </div>
      )}
    </div>
  )
}
