import Link from 'next/link'

export function addressLink(account) {
  if (!account) return
  return (
    <Link href={`/profile/${account?.id} `}>
      <p className='truncate cursor-pointer'>
        {' '}
        {account?.name || formatWalletAddress(account?.wallet || account?.cloud_wallet || account.id)}
      </p>
    </Link>
  )
}

export function formatWalletAddress(address?: string): string {
  if (typeof address !== 'string') return ''
  return address.substr(0, 6) + '…' + address.substr(address.length - 3, 3)
}
